import React from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";

export default () => (
  <div className={classes.container}>
    <p>
      <br />
      <Link to="/blog/how-to-publish-your-game-in-china/">
        Publishing a game in China
      </Link>{" "}
      on any platform - whether console, mobile, online, or Steam - first
      requires a game license from the Chinese government, issued by the{" "}
      <a
        href="http://www.sapprft.gov.cn/sapprft/"
        target="_blank"
        rel="noopener noreferrer"
      >
        State Administration of Press, Publications, Radio, Film and Television
        (SAPPRFT)
      </a>
      , which is now the{" "}
      <a
        href="http://www.nppa.gov.cn/"
        target="_blank"
        rel="noopener noreferrer"
      >
        National Press and Publication Administration (NPPA)
      </a>
      . Once this long, multi-step process is achieved, you will be issued a
      license for your game, and any name change or major content modifications
      would require a new license. Both foreign and domestic games are held to
      the same standard.
      <br />
      <br />
      This searchable database of games license approvals is updated on a
      monthly basis and translated into English from the{" "}
      <a
        href="http://www.sapprft.gov.cn/sapprft/channels/7026.shtml"
        target="_blank"
        rel="noopener noreferrer"
      >
        official government records
      </a>{" "}
      of all game licenses issued in China since 2009.
      <br />
      <br />
      Only Chinese companies and foreign companies that partner with Chinese
      companies are eligible to receive a game license for their game. If you
      want to release your game in China, we can help! Please{" "}
      <Link to="/get-started/">contact us</Link> to learn more about{" "}
      <Link to="/blog/how-to-publish-your-game-in-china/">the process</Link>
      .
      <br />
    </p>

    <h4>Game License Revocations</h4>
    <p>
      Whenever the NPPA revokes game licenses, we remove them from our database.
      You can find a full list of revoked game licenses{" "}
      <a
        href="https://wp.appinchina.co/wordpress/wp-content/uploads/2020/12/China-Game-License-Revocations.xlsx"
        target="_blanl"
        rel="noopener noreferrer"
      >
        here.
      </a>
    </p>
    <h4>
      Click below to search the full government record of Chinese game licenses
    </h4>
  </div>
);
