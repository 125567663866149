import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import Layout from "components/shared/Layout";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/certificate_h.svg";
import LicensesText from "components/games/LicensesText";
import LicensesTable from "components/games/LicensesTable";

export default () => (
  <>
    <Meta url="/games/game-licenses/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      black="Database"
      green="China Game License"
      text="Searchable database of games license approvals in China, compiled from official Chinese goverment data and translated into English for easy reference."
    />
    <Layout>
      <LicensesText />
      <LicensesTable />
    </Layout>
    <CTA />
    <Footer />
  </>
);
