import React from "react";
import * as classes from "./index.module.less";
import Button from "components/shared/Button";
import right from "images/svg_icons/right.svg";

export default () => (
  <div className={classes.container}>
    <a
      href="https://game-licenses.appinchina.co/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Button icon={right}>Visit Page</Button>
    </a>
  </div>
);
